.Footer {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  color: #343434;
  margin-top: 60px;
  background-color: #FAFAFF;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Footer .box-left {
  margin-top: 10px;
  justify-content: center;
  text-align: right;
  min-width: 500px;
}

.Footer .box-left a {
  text-decoration: none;
  color: inherit;
}

.Footer .box-right {
  margin-top: 10px;
  justify-content: center;
  min-width: 500px;
}

.Footer .box-center {
  margin: 56px 50px 64px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer .box-center img {
  width: 80px;
  height: 80px;
}

.Footer .box-center img {
  animation: rotation 10s infinite linear;
}

.Footer p {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 9px;
}

.Footer h1 {
  margin-bottom: 16px;
  color: #87D9D9;
  font-size: 24px;
  line-height: 29px;
}

@media screen and (max-width: 1200px) {
  .Footer .box-left {
    min-width: 400px;
  }

  .Footer .box-right {
    min-width: 400px;
  }
}

@media screen and (max-width: 1050px) {

  .Footer {
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .Footer .box-left {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }

  .Footer .box-right {
    text-align: center;
    width: 100%;
  }
  
}

@media screen and (max-width: 500px) {
  .Footer .box-right {
    min-width: unset;
  }

  .Footer .box-left {
    min-width: unset;
  }
}