.Services .slide {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: -1;
}

.Services .slide h1 {
  color: #343434;
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  text-transform: uppercase;
  max-width: 531px;
  transform: translate(-50px, -20px);
  z-index: -1;
}

.Services .slide em {
  font-style: unset;
  color: #87D9D9;
}

.Services .services-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-width: 1030px;
}

.Services .service-button {
  background: #FAFAFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 250px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: ease-in-out 400ms;
  color: #343434;
  user-select: none;
}

.Services .services-container a {
  text-decoration: none;
}

.Services .service-button:hover {
  color: #87D9D9;
  box-shadow: 0px 4px 4px #87D9D9;
  transition: ease-in-out 400ms;
}

.Services .service-button h1 {
  font-weight: 400;
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
}

@media screen and (max-width: 1100px) {

  .Services .slide img {
    width: 50vw;
  }

  .Services .slide h1 {
    font-size: 4vw;
    transform: translate(-30px, -10px);
  }

  .Services .service-button h1 {
    font-size: 1.4rem;
  }

  .Services .service-button {
    width: 35vw;
    height: 12vh;
  }

}

@media screen and (max-width: 600px) {
  .Services .service-button h1 {
    font-size: 1rem;
  }
}