.TablePrice {
  margin-top: 150px;
  max-width: 1030px;
}

.TablePrice h1 {
  color: #343434;
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  text-transform: uppercase;
  margin-bottom: 26px;
}

.TablePrice em {
  font-style: unset;
  color: #87D9D9;
}

.TablePrice .table {
  margin-top: 45px;
  max-width: 1000px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 50px;
}

.TablePrice .table .row {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 50px;
  align-items: center;
  padding-left: 10px;
  gap: 10px;
}

.TablePrice .table .row .column1 {
  width: 60%;
}


.TablePrice .table .row .column2 {
  width: 15%;
}

.TablePrice .table .row .column3 {
  width: 25%;
}

.title-row {
  color: #FFFFFF;
  background-color: #B9C6E4;
  text-align: left;
}

.TablePrice .table .row .column2, .TablePrice .table .row .column3 {
  text-align: center;
}

.white-row {
  background-color: #FAFAFF;
  border-top: 1px solid #343434;
}

/* Removed due to the suppression of the color contrast */
.gray-row {
  /* color: #FFFFFF; */
  /* background-color: #CECECE; */
  /* border: 1px solid #343434; */
}

@media screen and (max-width: 1100px) {
  .TablePrice h1 {
    padding-left: 20px;
    font-size: 4vw;
  }

  .TablePrice p {
    padding-left: 20px;
  }

  .TablePrice .table {
    margin-top: 35px;
    padding: 25px;
    font-size: 15px;
  }

  .TablePrice .table .row .column1 {
    width: 40%;
  }

  .TablePrice .table .row .column2 {
    width: 30%;
  }

  .TablePrice .table .row .column3 {
    width: 30%;
  }
  
}