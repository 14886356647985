@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  padding: 0;
  margin: 0;
}

body {
  background-color: #FAFAFF;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #343434;
}

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  max-width: 1400px;
  min-height: 800px;
}