.planity {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 700px;
}


.css-1wvg5ml button {
    background-color: #f44336 !important;
    font-size: 1rem;
    color: white;
    font-family: 'Inter', sans-serif;
}

.css-1wvg5ml button:hover {
    color: white;
    border-color: gray;
}

.css-151oww3 {
    background-color: unset !important;
}

.planity-component-button {
    font-family: 'Inter', sans-serif !important;
}

.css-1fesdig {
    margin-top: 0 !important;
}

.css-112cgga {
    padding: 20px 30px !important;
    border-radius: 6px;
}

@media screen and (max-width: 700px) {
    .planity {
        min-width: unset;
        padding: 0 30px;
    }
  }