.Error404 {
  text-align: center;
}

.Error404 h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  text-transform: uppercase;
  color: #343434;
}

.Error404 img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 800px) {
  .Error404 h1 {
    font-size: 1.3rem;
  }
}