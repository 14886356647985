.slider-container {
    width: 75vw;
}

.swiper {
    border-radius: 20px;
}

.swiper-button-next {
    color: #FAFAFF;
}

.swiper-button-prev {
    color: #FAFAFF;
}

.swiper img {
    border-radius: 20px;
    width: 600px;
    height: 400px;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .swiper img {
        border-radius: 20px;
        width: 400px;
        height: 300px;
        object-fit: cover;
    }
}