
/* General Styles */
div.OffreDecouverte {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

/* Heading Styles */
div.OffreDecouverte h1 {
    font-size: 2em;
    margin-bottom: 10px;
}

div.OffreDecouverte h3 {
    margin-top: 40px;
}

/* Table Styles */
div.OffreDecouverte table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 0.9em;
}

div.OffreDecouverte table, th, td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
}

div.OffreDecouverte thead th {
    background-color: #f2f2f2;
    color: #333;
}

div.OffreDecouverte tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

div.OffreDecouverte tbody tr:hover {
    background-color: #f1f1f1;
}

/* Special Text Styles */
div.OffreDecouverte s {
    color: #999;
}

div.OffreDecouverte th, td {
    text-align: center;
}

div.OffreDecouverte th {
    font-weight: bold;
}

div.OffreDecouverte span {
    color: #87D9D9;
    font-weight: bold;
}

div.OffreDecouverte p {
    margin: 20px 0;
    line-height: 1.5;
}
