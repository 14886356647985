.css-1u1908p {
    display: none;
}

.css-of2it5 {
    display: none !important;
}

.css-12ynb14 {
    margin: 0 !important;
    padding: 0 !important;
}

.css-go1g7c {
    margin: 0 !important;
}

.css-1p5afc1, .css-tqzes0 {
    display: none !important;
}

.planity {
    margin-top: 15px;
}

@media screen and (max-width: 500px) {
    .planity {
        width: 85vw;
    }

    .container {
        max-width: 100vw;
    }

  }