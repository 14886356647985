.Header {
  margin-bottom: 0 !important;
}

.ReserverEnLigne {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ReserverEnLigne .slide {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 90px;
  align-items: center;
}

.ReserverEnLigne .slide .text-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: #343434;
  max-width: 550px;
}

.ReserverEnLigne .slide .text-content h1 {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
}

.ReserverEnLigne .slide .text-content em {
  font-style: unset;
  color: #87D9D9;
}

.ReserverEnLigne .slide img {
  margin-top: 36px;
}

.ReserverEnLigne form#form-choices {
  /* border: red dashed; */
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 800px;
  margin: 0 20px 30px 20px;
}


.ReserverEnLigne form#form-choices .buttons {
  display: flex;
  justify-content: center;
}

.ReserverEnLigne form#form-choices .buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #87D9D9;
  color: #FFFFFF;
  text-decoration: none;
  border-radius: 20px;
  width: 220px;
  height: 30px;
  border: unset;
  cursor: pointer;
  user-select: none;
  font-size: 1.1rem;
}

.ReserverEnLigne form#form-choices .choices {
  /* border: blue dashed; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-width: 700px;
}

.ReserverEnLigne form#form-choices .head h1 {
  margin-bottom: 15px;
  font-weight: 400;
}

.ReserverEnLigne form#form-choices .choices .choice {
  /* padding: 10px 15px;
  background-color: white;
  border-radius: 16px; */
  user-select: none;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.ReserverEnLigne form#form-choices .choices .choice input[type='checkbox'] {
  cursor: pointer;
  accent-color: #87D9D9;
}

.ReserverEnLigne form#form-choices .choices .choice label {
  cursor: pointer;
}

.ReserverEnLigne form#form-reset {
  margin-bottom: 30px;
}

.ReserverEnLigne form#form-reset .buttons {
  display: flex;
  justify-content: center;
}

.ReserverEnLigne form#form-reset .buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d0d0d0;
  color: #FFFFFF;
  text-decoration: none;
  border-radius: 20px;
  width: 220px;
  height: 30px;
  border: unset;
  cursor: pointer;
  user-select: none;
  font-size: 1.1rem;
}

.ReserverEnLigne .call-to-gift {
  text-align: center;
}

.ReserverEnLigne .call-to-gift a {
  text-decoration: none;
  color: #87D9D9;
}

.ReserverEnLigne .documentation {
  margin: 0 20px 30px 20px;
  max-width: 800px;
}

.ReserverEnLigne .documentation a {
  color: #87D9D9;
  text-decoration: none;
}

.ReserverEnLigne .documentation h1 {
  margin-bottom: 15px;
}

@media screen and (max-width: 1100px) {

  .ReserverEnLigne .slide img {
    width: 50vw;
  }

  .ReserverEnLigne .slide h1 {
    font-size: 4vw;
  }

  .ReserverEnLigne .service-button h1 {
    font-size: 1.4rem;
  }

  .ReserverEnLigne .service-button {
    width: 35vw;
    height: 12vh;
  }

}

@media screen and (max-width: 800px) {
  .ReserverEnLigne .slide {
    padding: 0 20px;
    margin-bottom: 50px;
  }
  
    .ReserverEnLigne .slide img {
      width: 40vw;
    }
  
  .ReserverEnLigne .slide .text-content {
    gap: 15px;
  }
  
    .ReserverEnLigne .slide .text-content h1 {
      font-size: 1.3rem;
    }
  }

@media screen and (max-width: 600px) {
  .ReserverEnLigne .service-button h1 {
    font-size: 1rem;
  }
}

  
  @media screen and (max-width:500px) {
    .ReserverEnLigne .slide {
      flex-direction: column;
      text-align: center;
    }
  
    .ReserverEnLigne .slide img {
      margin-top: unset;
      width: 60vw;
    }
  }