header {
  display: flex;
  flex-direction: column;
  height: 140px;
  padding: 0 2rem;
  background: rgba(250, 250, 255, 0.7);
  backdrop-filter: blur(12.5px);
  font-size: 18px;
  color: #343434;
  position: sticky;
  top: 0;
  margin-bottom: 50px;
  z-index: 999;
}

header div:nth-child(1) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: #333333;
  border-radius: 16px;
  text-align: center;
  margin-top: 10px;
}

header div:nth-child(1) a {
  color: #333333;
  text-decoration: none;
}

header div:nth-child(1) h1 {
  font-size: medium;
}

header div:nth-child(2) {
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

header div:nth-child(2) img {
  width: 4rem;
}

nav a {
  margin: 0 2rem;
  color: #343434;
  text-decoration: none;
  transition: 500ms;
}

nav a:hover {
  color: #87D9D9;
  transition: 500ms;
}

header div:nth-child(2) .nav-btn{
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
  display: none;
  color: #333333;
}

header div:nth-child(2) .button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #87D9D9;
  color: #FFFFFF;
  text-decoration: none;
  border-radius: 20px;
  width: 220px;
  height: 30px;
}

@media only screen and (max-width: 1120px) {

  header div:nth-child(2) .nav-btn {
    visibility: visible;
    opacity: 1;
    display: unset;
  }

  header div:nth-child(2) nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: #FAFAFF;
    transform: translateY(-100vh);
    transition: 600ms;
    z-index: 1;
  }

  header div:nth-child(2) .responsive-nav {
    transition: 600ms;
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 3rem;
    right: 3rem;
  }

  nav a {
    font-size: 1.5rem;
  }

}

@media screen and (max-width:500px) {
  header div:nth-child(2) img {
    width: 50px;
    height: 50px;
    display: none;
  }
}